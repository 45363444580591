



































































































































































































































































































































































































.password-decription {
  margin-top: 12px;
  margin-bottom: 10px;
  font-family: "Raleway Regular", serif;
  font-size: 14px;
}

.terms-text {
  margin-top: 20px;
  font-family: "Raleway Regular", serif;
  font-size: 14px;

  a {
    color: #ff8a00;
    text-decoration: underline;
  }
}
